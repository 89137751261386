import React from "react";
import { useContractRead } from "wagmi";

import Gram180Token from "../../ABI/Gram180Token.json";
import { enabledChains } from "../../utils";

type TProps = {
  token: number;
  contract: `0x${string}`;
};

export default function Price({ token, contract }: TProps) {
  const totalSupply = useContractRead({
    address: contract,
    abi: Gram180Token,
    functionName: "totalSupply",
    chainId: enabledChains[0].id,
    args: [token],
  });

  const maxSupply = useContractRead({
    address: contract,
    abi: Gram180Token,
    functionName: "_maxSupply",
    chainId: enabledChains[0].id,
    args: [token],
  });

  const lol = useContractRead({
    address: contract,
    abi: Gram180Token,
    functionName: "_mintPrice",
    chainId: enabledChains[0].id,
    args: [token],
  });

  const getData = (data: any) => {
    return Number(data.data) / 1000000;
  };

  const getDataSold = (data: any) => {
    return Number(data.data);
  };

  if (Number(getDataSold(totalSupply)) === Number(getDataSold(maxSupply))) {
    return (
      <>
        <div
          style={{
            textDecoration: "line-through",
            fontSize: 10,
            lineHeight: 1,
          }}
        >
          ${lol ? getData(lol) : ""}
        </div>
        <div>SOLD OUT!</div>
      </>
    );
  }

  return <>${lol ? getData(lol) : ""}</>;
}
