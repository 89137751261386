import { createStore, applyMiddleware, compose } from "redux";
import {thunk} from "redux-thunk";
import { rootReducer } from "./reducers";

export type AppState = ReturnType<typeof rootReducer>;

// Compose enhancers for Redux DevTools and middleware
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store with the root reducer and middleware
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export { store };
