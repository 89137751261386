import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../assets/images/coverImg.png";
import Play from "../assets/images/playButton.inline.svg";
import { getImage, getRandomPlaceholder } from "../utils";
const placeholder = getRandomPlaceholder();

function ArtistItem({ image, name, tracks, permalink }: any) {
  return (
    <div className="card">
      <div
        className="card-body"
        style={{ textAlign: "center", paddingTop: 24 }}
      >
        <div className="mask3 artist-image3" style={{ position: "relative" }}>
          <Link to={"/artist/" + permalink}>
            <img
              src={image === "" ? placeholder : getImage(image)}
              alt={name}
            />
          </Link>
        </div>

        <h4>{name}</h4>
        <h6>Tracks: {tracks}</h6>
        <hr />
        <div>
          <Link to={"/artist/" + permalink} className="btn btn-solid btn-xs">
            Artist detail
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ArtistItem;
