import React from "react";
import Icon from "../assets/images/logo-icon.svg";

function LogoFooter() {
  return (
    <div className="logo d-flex align-items-center">
      <span className="icon me-2">
        <img src={Icon} className="d-block" alt="icon" width={155} />
      </span>
    </div>
  );
}

export default LogoFooter;
