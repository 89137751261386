import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface ArtistsInterface {
  readonly type: "LOAD_ARTISTS";
  payload: any;
}

export type LoadArtists = ArtistsInterface;

export const loadArtists = () => {
  return async (dispatch: Dispatch<ArtistsInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getArtists.php?cache=${Date.now()}`
      // );
      console.log(BASE_URL);
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/artists`
      );
      console.log(response);  
      if (!response.status) {
      } else {
        dispatch({
          type: "LOAD_ARTISTS",
          payload: response.data.payload,
        });
      }
    } catch (error) {}
  };
};
