import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import "react-loading-skeleton/dist/skeleton.css";

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { WagmiProvider } from "wagmi";
import { wagmiProjectId } from "./utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { polygonAmoy } from "viem/chains";

const queryClient = new QueryClient();

const projectId =
  wagmiProjectId ||
  (() => {
    throw new Error("undefined variable");
  })();

const chains = [polygonAmoy] as const;
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const config = defaultWagmiConfig({
  projectId,
  chains,
  metadata,
});

createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  themeVariables: {
    "--w3m-font-family": "Satoshi, sans-serif",
    "--w3m-accent": "#7b3fe4",
  },
});

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 2, chains }),
//   publicClient,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WagmiProvider>
      {/* <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-font-family": "Satoshi, sans-serif",
          "--w3m-accent-color": "#7b3fe4",
          "--w3m-background-color": "#7b3fe4",
          "--w3m-z-index": "1111",
        }}
      /> */}
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>
);
