import React, { useEffect } from "react";
import { useWriteContract, useWaitForTransactionReceipt } from "wagmi";

import Gram180Token from "../../ABI/Gram180Token.json";
import { Button } from "react-bootstrap";
import Loading from "../Loading";

export default function Mint({
  contract,
  account,
  token,
  onError,
  onSuccess,
}: any) {
  // const { config } = usePrepareContractWrite({
  //   address: contract,
  //   abi: Gram180Token,
  //   functionName: "mintUSDC",
  //   chainId: enabledChains[0].id,
  //   args: [account, token],
  // });

  const { writeContract, data, error, status } = useWriteContract();
  console.log("data", data);
  console.log("error", error);
  // const { data, error, isLoading, write } = useContractWrite(config);

  const { data: transactionData, error: transactionError } =
    useWaitForTransactionReceipt({ hash: data });

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error]);

  useEffect(() => {
    if (transactionError) {
      onError(transactionError);
    }
  }, [transactionError]);

  useEffect(() => {
    if (transactionData) {
      onSuccess(transactionData);
    }
  }, [transactionData]);

  return (
    <div className="Approve">
      {status == "pending" ? (
        <Loading width={80} height={80} />
      ) : (
        <>
          {writeContract && (
            <Button
              variant="solid"
              onClick={() =>
                writeContract({
                  address: contract,
                  abi: Gram180Token,
                  functionName: "mintUSDC",
                  args: [account, token],
                })
              }
              className="mb-24"
            >
              MINT TRACK
            </Button>
          )}
        </>
      )}
    </div>
  );
}
