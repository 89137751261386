import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface FeaturedArtistInterface {
  readonly type: "LOAD_FEATURED_ARTIST";
  payload: any;
}

export type LoadFeaturedArtist = FeaturedArtistInterface;

export const loadFeaturedArtist = () => {
  return async (dispatch: Dispatch<FeaturedArtistInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getFeaturedArtist.php?cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/artist-featured`
      );

      if (!response.status) {
      } else {
        dispatch({
          type: "LOAD_FEATURED_ARTIST",
          payload: response.data.payload,
        });
      }
    } catch (error) {}
  };
};
