import React, { useEffect } from "react";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

import USDC from "../../ABI/USDC.json";
import { Button } from "react-bootstrap";
import Loading from "../Loading";
import { enabledChains, USDC_CONTRACT } from "../../utils";

export default function Approve({ contract, price, onSuccess, onError }: any) {

  const { data, error, writeContract,isPending } = useWriteContract();
  useEffect(() => {
    console.log("error", error);
  }, [error]);
  const {
    data: transactionData,
    error: transactionError,
    isLoading: isLoadingTransaction,
  } = useWaitForTransactionReceipt({
    hash: data
  });

  useEffect(() => {
    if (transactionData) {
      onSuccess(transactionData);
    }
  }, [transactionData]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error]);

  useEffect(() => {
    if (transactionError) {
      onError(transactionError);
    }
  }, [transactionError]);

  return (
    <>
      {isPending || isLoadingTransaction ? (
        <Loading width={80} height={80} />
      ) : (
        <>
          {writeContract ? (
            <Button
              variant="solid"
              onClick={async () => writeContract({
                address: USDC_CONTRACT,
                abi: USDC,
                functionName: "approve",
                args: [contract, price],
              })}
              className="mb-24"
            >
              Approve USDC spend
            </Button>
          ) : (
            <Loading width={80} height={80} />
          )}
        </>
      )}
    </>
  );
}
