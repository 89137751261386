import { LoadFeaturedArtist } from "../actions/featuredArtistActions";

type FeaturedArtistState = {
  featuredArtist: any;
  error: string | undefined;
};

const initialState = {
  featuredArtist: [] as any[],
  error: undefined,
};

const FeaturedArtistReducer = (
  state: FeaturedArtistState = initialState,
  action: LoadFeaturedArtist
) => {
  switch (action.type) {
    case "LOAD_FEATURED_ARTIST":
      return {
        ...state,
        featuredArtist: action.payload,
      };

    default:
      return state;
  }
};

export { FeaturedArtistReducer };
