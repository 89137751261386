import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface ArtistInterface {
  readonly type: "LOAD_ARTIST";
  payload: any;
}

export type LoadArtist = ArtistInterface;

export const loadArtist = (permalink: string) => {
  return async (dispatch: Dispatch<ArtistInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getArtist.php?permalink=${permalink}&cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/artist/${permalink}`
      );
      if (!response.status) {
      } else {
        dispatch({
          type: "LOAD_ARTIST",
          payload: response.data.payload,
        });
      }
    } catch (error) {}
  };
};
