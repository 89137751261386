import { LoadTracksLatest } from "../actions/tracksLatestActions";

type TracksLatestState = {
  tracksLatest: any;
  error: string | undefined;
};

const initialState = {
  tracksLatest: [] as any,
  error: undefined,
};

const TracksLatestReducer = (
  state: TracksLatestState = initialState,
  action: LoadTracksLatest
) => {
  switch (action.type) {
    case "LOAD_TRACKS_LATEST":
      return {
        ...state,
        tracksLatest: action.payload,
      };

    default:
      return state;
  }
};

export { TracksLatestReducer };
