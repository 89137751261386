import React from "react";
import Icon from "../assets/images/logo-icon.svg";

function Logo() {
  return (
    <div className="logo d-flex align-items-center">
      <span className="icon">
        <img src={Icon} className="d-sm-block" alt="icon" width={155} />
      </span>
    </div>
  );
}

export default Logo;
