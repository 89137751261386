import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

function CardLoading({}: any) {
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);

  return (
    <div className="card">
      <div className="card-image loading">
        <Skeleton
          height={271}
          style={{ top: -8 }}
          baseColor={"#F1F4F8"}
          highlightColor={"#E5E8EB"}
        />
      </div>
      <div className="card-body">
        <h6>
          <Skeleton
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
            width={70}
          />
        </h6>
        <h4>
          <Skeleton
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
            width={100}
          />
        </h4>
        <h6>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </h6>

        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <Skeleton
            height={30}
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
            width={100}
          />
          <Skeleton
            height={30}
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
            width={100}
          />
        </div>
      </div>
    </div>
  );
}

export default CardLoading;
