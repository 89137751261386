import React, { useState } from "react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { Button, Modal } from "react-bootstrap";
import PurpleShape from "../../assets/images/purpleShape.svg";
import ErrorShape from "../../assets/images/errorShape.svg";
import Wallet from "../../assets/images/empty-wallet-change.svg";
import WalletTick from "../../assets/images/empty-wallet-tick.svg";
import WalletTickError from "../../assets/images/empty-wallet-remove.svg";

import Loading from "../Loading";
import { BASE_URL,CROSSMINT_PROJECT_ID, CROSSMINT_ENVIRONMENT } from "../../utils";
import axios from "axios";
import MintSuccess from "../MintSuccess";

export default function Crossmint({ price, clientId, tokenId, contract }: any) {
  const userToken =
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7) +
    (Math.random() + 1).toString(36).substring(7);

  const [showWaiting, setShowWaiting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const startChecking = () => {
    setShowWaiting(true);

    var myInterval = setInterval(async () => {
      try {
        const response = await axios.get<any>(
            `${BASE_URL}/api/v1/crossmint/check/${userToken}`
        );

        if (!response) {
          setShowSuccess(false);
          setShowWaiting(false);
          setShowError(true);
          clearInterval(myInterval);
        } else {
          if (response.data.payload === true) {
            setShowSuccess(true);
            setShowWaiting(false);
            setShowError(false);
            clearInterval(myInterval);
          } else if (response.data.payload === false) {
            setShowSuccess(false);
            setShowWaiting(false);
            setShowError(true);
            clearInterval(myInterval);
          }
        }
      } catch (error) {
        setShowSuccess(false);
        setShowWaiting(true);
        setShowError(false);
      }
    }, 5000);
  };

  const closeWaiting = () => {
    setShowWaiting(false);
  };

  const closeError = () => {
    setShowError(false);
  };

  const closeSuccess = () => {
    setShowSuccess(false);

    //@ts-ignore
    window.location.reload(false);
  };

  return (
    <>
      <CrossmintPayButton
          collectionId={clientId}
          projectId={CROSSMINT_PROJECT_ID}
          mintConfig={{type: "erc-1155","totalPrice":String(price / 1000000),"id":tokenId}}
          environment={CROSSMINT_ENVIRONMENT}
          checkoutProps={{"paymentMethods":["fiat","ETH","SOL"]}}
          whPassThroughArgs={userToken}
        style={{
          whiteSpace: "nowrap",
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 18,
          paddingRight: 18,
          letterSpacing: 1.2,
          fontSize: 10,
          lineHeight: 1.21,
          textIndent: 2,
          textTransform: "uppercase",
          fontWeight: 600,
          fontFamily: "Satoshi",
          borderRadius: 25,
        }}
        className={"ms-auto"}
        onClick={() => {
          startChecking();
        }}
        showOverlay={false}
      />

      <Modal show={showWaiting} centered onHide={closeWaiting}>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <div className="modal-icon">
              <img src={PurpleShape} alt="Mint" className="img-fluid" />
              <img src={Wallet} alt="Mint" className="modal-icon-front" />
            </div>
          </div>

          <h4 className="h4 mb-md-3 mb-1">Finalize at Crossmint</h4>
          <p className="mb-24">
            By choosing a credit card payment, you have been taken to our
            partner’s website to finalize the process. Please finish the
            purchase at Crossmint and come back.
          </p>
          <Loading width={80} height={80} />
        </Modal.Body>
      </Modal>

      <Modal show={showError} centered onHide={closeError}>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <div className="modal-icon">
              <img src={ErrorShape} alt="Error" className="img-fluid" />
              <img
                src={WalletTickError}
                alt="Error"
                className="modal-icon-front"
              />
            </div>
          </div>

          <h4 className="h4 mb-md-3 mb-1">Crossmint Error</h4>
          <p className="mb-24">
            There has been an error during your Crossmint payment. If you didn’t
            get a confirmation email from Crossmint, please try again later.
          </p>
        </Modal.Body>
      </Modal>

      <MintSuccess
        show={showSuccess}
        onHide={closeSuccess}
        handleCloseSuccess={closeSuccess}
        contract={contract}
        account={"Crossmint"}
        token={tokenId}
      />
    </>
  );
}
