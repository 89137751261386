import { LoadTracksLatestFilter } from "../actions/tracksLatestFilterActions";

type TracksLatestFilterState = {
  tracksLatestFilter: any;
  error: string | undefined;
};

const initialState = {
  tracksLatestFilter: [] as any,
  error: undefined,
};

const TracksLatestFilterReducer = (
  state: TracksLatestFilterState = initialState,
  action: LoadTracksLatestFilter
) => {
  switch (action.type) {
    case "LOAD_TRACKS_LATEST_FILTER":
      return {
        ...state,
        tracksLatestFilter: action.payload,
      };

    default:
      return state;
  }
};

export { TracksLatestFilterReducer };
