import {polygonAmoy} from 'wagmi/chains'

export const USDC_CONTRACT = "0x14196F08a4Fa0B66B7331bC40dd6bCd8A1dEeA9F";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CROSSMINT_PROJECT_ID = process.env.REACT_APP_CROSSMINT_PROJECT_ID;
export const CROSSMINT_ENVIRONMENT = process.env.REACT_APP_CROSSMINT_ENVIRONMENT;
export const BASE_URL2 = process.env.REACT_APP_BASE_URL2;

export const WEB_URL = process.env.REACT_APP_WEB_URL;
export const IPFS_URL = process.env.REACT_APP_IPFS_URL;
export const POLYGONSCAN_URL = process.env.REACT_APP_POLYGONSCAN_URL;
export const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;

export const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export * from "./helpers";
export const enabledChains = [polygonAmoy];
export const wagmiProjectId = process.env.REACT_APP_PROJECT_ID;
