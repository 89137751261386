import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Menu from "../assets/images/list.svg";
import Close from "../assets/images/menuClose.svg";

import { ApplicationState, loadPlay } from "../store";
import { useSelector, useDispatch } from "react-redux";

function Header() {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const { play } = useSelector((state: ApplicationState) => state.playReducer);

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await loadPlay("");
  };

  return (
    <Navbar expanded={show} className="position-absolute" expand="md">
      <Container>
        <Link
          className="navbar-brand"
          // onClick={() => setShow(!show)}
          to="/"
        >
          <Logo />
        </Link>
        <Navbar.Toggle
          onClick={() => setShow(!show)}
          className="p-0 border-0"
          aria-controls="basic-navbar-nav"
        >
          <img src={Menu} alt="menu bars" />
          <img src={Close} alt="menu bars" />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-none d-md-flex">
            <Link className="nav-link" to="/how-it-works">
              How it works
            </Link>
            <Link className="nav-link" to="/latest">
              Latest releases
            </Link>
            <Link className="nav-link" to="/artists">
              Artists
            </Link>
            <Link className="nav-link" to="/about-us">
              About us
            </Link>
          </Nav>

          <Nav className="ms-auto d-md-none">
            <Link
              onClick={() => setShow(!show)}
              className="nav-link"
              to="/how-it-works"
            >
              How it works
            </Link>
            <Link
              onClick={() => setShow(!show)}
              className="nav-link"
              to="/latest"
            >
              Latest releases
            </Link>
            <Link
              onClick={() => setShow(!show)}
              className="nav-link"
              to="/artists"
            >
              Artists
            </Link>
            <Link
              onClick={() => setShow(!show)}
              className="nav-link"
              to="/about-us"
            >
              About us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {/* {JSON.stringify(play)} */}
    </Navbar>
  );
}

export default Header;
