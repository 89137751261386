import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./LogoFooter";
import Close from "../assets/images/menuClose.svg";
import React from "react";

function Footer() {
  const [showTerms, setShowTerms] = React.useState(false);

  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="justify-content-sm-between text-sm-start text-center justify-content-center align-items-center">
            <Col
              xs={12}
              className="d-flex justify-content-center mb-md-0 mb-4"
              md={"auto"}
            >
              <Logo />
            </Col>
            <Col xs={12} sm={"auto"} md={"auto"} className="mb-sm-0 mb-3">
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowTerms(true);
                }}
                className="border-bottom"
              >
                Terms & Conditions
              </Link>
            </Col>
            <Col xs={12} sm={"auto"} md={"auto"}>
              <span>© Copyright {new Date().getFullYear()} Gram180</span>
            </Col>
          </Row>
        </Container>
      </footer>

      <Modal show={showTerms} onHide={() => setShowTerms(false)}>
        <button
          className="modal-close-button"
          onClick={() => setShowTerms(false)}
        >
          <img src={Close} alt="Close" />
        </button>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <h3>Terms & Conditions</h3>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Pellentesque ipsum. Aliquam erat volutpat. Sed vel lectus. Donec
              odio tempus molestie, porttitor ut, iaculis quis, sem. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos hymenaeos. Nam sed tellus id magna elementum tincidunt.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Footer;
