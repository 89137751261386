import { LoadPlay } from "../actions/playActions";

type PlayState = {
  play: any;
  error: string | undefined;
};

const initialState = {
  play: "" as string,
  error: undefined,
};

const PlayReducer = (state: PlayState = initialState, action: LoadPlay) => {
  switch (action.type) {
    case "LOAD_PLAY":
      return {
        ...state,
        play: action.payload,
      };

    default:
      return state;
  }
};

export { PlayReducer };
