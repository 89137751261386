import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface DetailInterface {
  readonly type: "LOAD_DETAIL";
  payload: any;
}

export type LoadDetail = DetailInterface;

export const loadDetail = (id: string, artistId: string) => {
  return async (dispatch: Dispatch<DetailInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getDetail.php?id=${id}&artist_id=${artistId}&cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/mint/${id}`
      );

      if (!response) {
      } else {
        dispatch({
          type: "LOAD_DETAIL",
          payload: response.data.payload,
        });
      }
    } catch (error) {}
  };
};
