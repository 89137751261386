import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "../components/CardItem";
import Image from "../assets/images/coverImg.png";
import { Link } from "react-router-dom";
import SocialIcon1 from "../assets/images/icoPolygon.svg";
import SocialIcon2 from "../assets/images/icoOpensea.svg";
import SocialIcon3 from "../assets/images/icoSpotify.svg";
import SocialIconApple from "../assets/images/icoApplemusic.svg";
import SocialIconTidal from "../assets/images/tidal.svg";
import SocialIconWeb from "../assets/images/world-www.svg";
import SocialIconInstagram from "../assets/images/instagram.svg";
import SocialIconFacebook from "../assets/images/facebook.svg";
import axios from "axios";
import LoadingImg from "../assets/images/loading-small.svg";

import { useParams } from "react-router-dom";

import { ApplicationState, onLoading, loadArtist } from "../store";
import { useSelector, useDispatch } from "react-redux";
import {
  BASE_URL,
  getImage,
  getOpenSeaLink,
  getPolygonScan,
  getRandomPlaceholder,
} from "../utils";
import CardLoading from "../components/CardItemLoading";
import Skeleton from "react-loading-skeleton";
import Error from "../components/Error";

const placeholder = getRandomPlaceholder();

function ArtistPage() {
  const dispatch = useDispatch();
  let { permalink } = useParams();

  const { artist } = useSelector(
      (state: ApplicationState) => state.artistReducer
  );

  const [offset, setOffset] = useState(1);

  const [tracksData, setTracksData] = useState([]) as any[];
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingTracks, setLoadingTracks] = useState(true);
  const [loadingArtist, setLoadingArtist] = useState(true);

  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (permalink) {
      loadData(permalink);
      loadTracks(permalink);
    }
  }, [permalink]);

  useEffect(() => {
    if (artist.success === "false") {
      setNotFound(true);
    }
  }, [artist]);

  const loadData = async (permalink: string) => {
    setLoadingArtist(true);
    await loadArtist(permalink);
    setLoadingArtist(false);
  };

  const loadTracks = async (permalink: string) => {
    setLoadingTracks(true);
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getTracksByPermalink.php?permalink=${permalink}&cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/tracks/by-permalink?permalink=${permalink}`
      );

      if (!response.status) {
        setLoadingTracks(false);
      } else {
        setTracksData(response.data.payload);
        setLoadingTracks(false);
      }
    } catch (error) {
      setLoadingTracks(false);
    }
  };

  const loadMoreTracks = async (offset: number) => {
    setLoadingMore(true);

    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getTracksByPermalink.php?permalink=${permalink}&offset=${offset}&cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/tracks/by-permalink?permalink=${permalink}&offset=${offset}`
      );
      if (!response.status) {
        setLoadingMore(false);
      } else {
        setOffset(offset + 1);
        setTracksData([...tracksData, ...response.data.payload]);
        if (response.data.payload.length < 4) {
          setShowLoadMore(false);
        }
        setLoadingMore(false);
      }
    } catch (error) {
      setLoadingMore(false);
    }
  };

  if (notFound) {
    return <Error number={404} />;
  }

  return (
      <main className="page-content bg-shape">
        <div className="pt-40 artist-detail">
          <Container className="inner mb-40">
            <Row className="align-items-center gx-lg-5 pb-40">
              <Col xs={12} sm={"auto"} className="mb-sm-0 mb-40">
                <div
                    className="mask2 artist-image2"
                    style={{ position: "relative" }}
                >
                  {loadingArtist ? (
                      <Skeleton
                          width={92}
                          height={94}
                          baseColor={"#F1F4F8"}
                          highlightColor={"#E5E8EB"}
                          style={{ position: "absolute" }}
                      />
                  ) : (
                      <img
                          src={
                            artist.image === "" ? placeholder : getImage(artist.image)
                          }
                          alt={artist.name}
                      />
                  )}
                </div>
              </Col>
              <Col className="flex-grow-1">
                <div className="mb-3 d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    {loadingArtist ? (
                        <h4 className="mb-2">
                          <Skeleton
                              width={200}
                              height={31}
                              baseColor={"#F1F4F8"}
                              highlightColor={"#E5E8EB"}
                          />
                        </h4>
                    ) : (
                        <h4 className="mb-2">{artist.name}</h4>
                    )}

                    {loadingArtist ? (
                        <p className="mb-0">
                          <Skeleton
                              width={130}
                              height={10}
                              baseColor={"#F1F4F8"}
                              highlightColor={"#E5E8EB"}
                          />
                        </p>
                    ) : (
                        <p className="artist-desc mb-0">TRACKS: {artist.tracks}</p>
                    )}
                  </div>

                  <div className="socials">
                    {loadingArtist ? (
                        <Skeleton
                            width={20}
                            height={20}
                            baseColor={"#F1F4F8"}
                            highlightColor={"#E5E8EB"}
                        />
                    ) : (
                        <>
                          {artist.contract !== "" && (
                              <a
                                  href={getPolygonScan(artist.contract)}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIcon1} alt="Polygon" />
                              </a>
                          )}

                          {artist.contract !== "" && (
                              <a
                                  href={getOpenSeaLink(artist.contract)}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIcon2} alt="OpenSea" />
                              </a>
                          )}

                          {artist.web_url !== "" && (
                              <a
                                  href={artist.web_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIconWeb} alt="Web" />
                              </a>
                          )}

                          {artist.spotify_url !== "" && (
                              <a
                                  href={artist.spotify_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIcon3} alt="Spotify" />
                              </a>
                          )}

                          {artist.apple_url !== "" && (
                              <a
                                  href={artist.apple_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIconApple} alt="Apple Music" />
                              </a>
                          )}

                          {artist.instagram_url !== "" && (
                              <a
                                  href={artist.instagram_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIconInstagram} alt="Instagram" />
                              </a>
                          )}

                          {artist.facebook_url !== "" && (
                              <a
                                  href={artist.facebook_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIconFacebook} alt="Facebook" />
                              </a>
                          )}

                          {artist.tidal_url !== "" && (
                              <a
                                  href={artist.tidal_url}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                <img src={SocialIconTidal} alt="Tidal" />
                              </a>
                          )}
                        </>
                    )}

                    {/* <Link to="/" target={"_blank"}>
                    <img src={SocialIcon4} alt="social 4" />
                  </Link> */}
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
          </Container>
        </div>

        {tracksData.length > 0 && (
            <div className="pb-80">
              <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="section-subheading mb-2 ml-2">
                  <h6 className="text-uppercase mb-0">Latest Tracks</h6>
                </div>

                <Row>
                  {tracksData.map((track: any) => (
                      <Col sm={6} md={4} lg={3} key={track.id}>
                        <Card
                            id={track.id}
                            name={track.name}
                            artist={track.artist}
                            image={getImage(track.image)}
                            user_id={track.user_id}
                            contract={track.contract}
                            transaction_data={track.transaction_data}
                            animation_url={track.animation_url}
                            permalink={track.permalink}
                        />
                      </Col>
                  ))}
                  {(loadingTracks || loadingMore) && (
                      <>
                        {[...Array(8)].map((elementInArray, index) => (
                            <Col sm={6} md={4} lg={3} key={String(index)}>
                              <CardLoading />
                            </Col>
                        ))}
                      </>
                  )}
                </Row>

                {showLoadMore && tracksData.length >= 8 && (
                    <div className="text-center">
                      <Button
                          variant="solid"
                          className="btn btn-lg"
                          onClick={() => loadMoreTracks(offset)}
                      >
                        {loadingMore && (
                            <img
                                src={LoadingImg}
                                alt="Loading"
                                className="loading-box"
                            />
                        )}{" "}
                        LOAD MORE
                      </Button>
                    </div>
                )}
              </Container>
            </div>
        )}
      </main>
  );
}

export default ArtistPage;