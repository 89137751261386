import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface TracksLatestFilterInterface {
  readonly type: "LOAD_TRACKS_LATEST_FILTER";
  payload: any;
}

export type LoadTracksLatestFilter = TracksLatestFilterInterface;

export const loadTracksLatestFilter = () => {
  return async (dispatch: Dispatch<TracksLatestFilterInterface>) => {
    try {
      // const response = await axios.get<any>(
      //   `${BASE_URL}getTracksLatestFilter.php?cache=${Date.now()}`
      // );
      const response = await axios.get<any>(
          `${BASE_URL}/api/v1/tracks/filtered`
      );

      if (!response) {
      } else {
        dispatch({
          type: "LOAD_TRACKS_LATEST_FILTER",
          payload: response.data.payload,
        });
      }
    } catch (error) {}
  };
};
