import { LoadDetail } from "../actions/detailActions";

type DetailState = {
  detail: any;
  error: string | undefined;
};

const initialState = {
  detail: {
    id: "",
    name: "",
    image: "",
    description: "",
    tokens: "",
    suggested_price: "",
    animation_url: "",
    release_date: "",
    genre: "",
    transaction: "",
    transaction_data: "",
    metadata: "",
    status: "",
    approved: "",
    updated: "",
    user_id: "",
    artist: "",
    contract: "",
    success: "",
  } as any,
  error: undefined,
};

const DetailReducer = (
  state: DetailState = initialState,
  action: LoadDetail
) => {
  switch (action.type) {
    case "LOAD_DETAIL":
      return {
        ...state,
        detail: action.payload,
      };

    default:
      return state;
  }
};

export { DetailReducer };
