import { LoadArtist } from "../actions/artistActions";

type ArtistState = {
  artist: any;
  error: string | undefined;
};

const initialState = {
  artist: {
    id: "",
    id_original: "",
    email: "",
    name: "",
    bio: "",
    image: "",
    web_url: "",
    facebook_url: "",
    spotify_url: "",
    status: "",
    transaction: "",
    transaction_data: "",
    contract: "0xc2ecb401a81cccdddbb30f03aec1dcdd6c636293",
    success: "",
  } as any,
  error: undefined,
};

const ArtistReducer = (
  state: ArtistState = initialState,
  action: LoadArtist
) => {
  switch (action.type) {
    case "LOAD_ARTIST":
      return {
        ...state,
        artist: action.payload,
      };

    default:
      return state;
  }
};

export { ArtistReducer };
