import React, { useState } from "react";
import { Link } from "react-router-dom";
import Price from "./chain/Price";
import { getMintToken } from "../utils";
import Sold from "./chain/Sold";
import Player from "./chain/Player";

function Card({
  id,
  name,
  image,
  artist,
  user_id,
  contract,
  transaction_data,
  animation_url,
  permalink,
}: any) {
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);

  return (
    <div className="card">
      <div className="card-image">
        <Link to={"/artist/" + permalink + "/" + id}>
          <img src={image} alt={name} />
        </Link>
      </div>
      <div className="card-body">
        <Player audioUrl={animation_url} id={id} />
        <h6>
          <Link to={"/artist/" + permalink}>{artist}</Link>
        </h6>
        <h4>{name}</h4>
        <h6>
          SOLD:{" "}
          <Sold token={getMintToken(transaction_data)} contract={contract} />
        </h6>

        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <div className="price">
            {
              <Price
                token={getMintToken(transaction_data)}
                contract={contract}
              />
            }
          </div>
          <Link
            to={"/artist/" + permalink + "/" + id}
            className="btn btn-solid btn-xs"
          >
            Detail
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Card;
