import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AboutUsImage from "../assets/images/about-us.png";
import Banner from "../assets/images/image.png";

function AboutUs() {
  return (
    <main className="page-content">
      <div className="pt-40 pb-80 bg-shape">
        <div className="col-grid mb-80">
          <Container>
            <Row className="justify-content-lg-between align-items-center justify-content-center">
              <Col xs={12} lg={5} className="mb-lg-0 mb-4">
                <div className="col-grid-image col-grid-left">
                  <img src={AboutUsImage} alt="AboutUs" />
                  <h2 className="mb-0">
                    <span>
                      NFTs and Music: Empowering Artists in the Digital Age
                    </span>
                  </h2>
                </div>
              </Col>
              <Col xs={12} lg={5}>
                <h4 className="h4 mb-0">
                  As artists, our primary goal is to create and share our music,
                  connect with people, introduce our sound to new audiences,
                  and, of course, make a living.
                </h4>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="page-information">
          <Container fluid="sm">
            <p>
              Lately, NFTs have been making headlines, with stories of artists
              earning millions in this new digital landscape. The world of NFTs
              may seem like a foreign language, filled with talk of crypto,
              blockchain, wallets, and marketplaces. It might sound complicated,
              but does it have to be?
            </p>

            <h5>Welcome to Gram180!</h5>
            <p>
              Our mission is to simplify the NFT process for recording artists
              by providing a user-friendly platform to create NFTs without
              needing any prior knowledge of the technology. We aim to integrate
              the tools you're already familiar with as a musician, streamlining
              the process to just 5 minutes.
            </p>
            <p>
              What is an NFT, and how does it relate to the music world? At
              Gram180, we see NFTs as the modern equivalent of classic vinyl
              records. Just as a vinyl record carries music in a tangible
              format, an NFT serves as a digital record that cannot be altered.
              We refer to the process of creating an NFT as "pressing," just
              like with vinyl records – different technology, same concept.
            </p>

            <blockquote>
              Our name, Gram180, is inspired by the highest-quality vinyl
              records, which weigh 180 grams. These records are more durable and
              long-lasting, just like NFTs.
            </blockquote>

            <h5>About Gram180</h5>
            <p>
              While exploring the NFT space, you might have encountered various
              services focusing on music, only to find that their primary
              interest lies in the NFT phenomenon itself rather than the music
              experience. We're here to change that.
            </p>
            <p>
              Gram180 is supported by industry professionals with over 20 years
              of experience in label management, music distribution, and
              production. Our team has collaborated with a diverse array of
              major labels and independent artists, from Beyoncé to the Wamdue
              Project, and managed labels distributed by Sony and Universal
              Music.
            </p>

            <blockquote>
              Our unique approach sets us apart: we're not an NFT company
              attempting to break into the music industry – we've already
              mastered it. Now, we're here to bring our expertise to the world
              of NFTs, empowering musicians and fans alike in this exciting
              digital era.
            </blockquote>
          </Container>
        </div>
      </div>
    </main>
  );
}

export default AboutUs;
