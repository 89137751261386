import React from "react";
import { useAccount, useReadContract, useDisconnect } from "wagmi";

import Gram180Token from "../../ABI/Gram180Token.json";
import { enabledChains } from "../../utils";
import { Button, Dropdown, ProgressBar } from "react-bootstrap";

import Creditcard from "../../assets/images/icoCreditcard.svg";
import USDC from "../../assets/images/icoUSDC.svg";
import Logos from "../../assets/images/icoLogos.png";
import Matic from "../../assets/images/icoMatic.svg";
import WalletConnectModal from "../WalletConnectModal";
import Price from "./Price";
import { getProgress, OPENSEA_URL, USDC_CONTRACT } from "../../utils";
import { Link } from "react-router-dom";
import Crossmint from "./Crossmint";

export default function SoldDetail({ token, contract, clientId }: any) {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const configTotalSupply = {
    address: contract,
    abi: Gram180Token,
    functionName: "totalSupply",
    chainId: enabledChains[0].id,
    args: [token],
  };

  const configMaxSupply = {
    address: contract,
    abi: Gram180Token,
    functionName: "_maxSupply",
    chainId: enabledChains[0].id,
    args: [token],
  };

  const configPrice = {
    address: contract,
    abi: Gram180Token,
    functionName: "_mintPrice",
    chainId: enabledChains[0].id,
    args: [token],
  };

  const configAllowance = {
    address: USDC_CONTRACT,
    abi: USDC,
    functionName: "allowance",
    chainId: enabledChains[0].id,
    args: [address, contract],
  };

  // const getData = (data: any) => {
  //   if (data && data.data) {
  //     return JSON.parse(data.data.payload ?? data.data);
  //   }
  //   return null;
  // };
  const getData = (data: any) => {
    return data.data ? JSON.parse(data?.data?.payload ?? data?.data) : null;
  };

  const totalSupply = useReadContract(configTotalSupply);
  const maxSupply = useReadContract(configMaxSupply);
  const price = useReadContract(configPrice);

  const totalSupplyLoading = useReadContract(configTotalSupply).isLoading;
  const maxSupplyLoading = useReadContract(configMaxSupply).isLoading;
  const priceLoading = useReadContract(configPrice).isLoading;

  if (typeof totalSupply === "undefined" || typeof maxSupply === "undefined") {
    return null;
  }
  // x

  if (Number(totalSupply.data) === Number(maxSupply.data)) {
    return (
      <div className="purchase-card purchase-card-sold">
        <h6 className="d-flex align-items-center justify-content-between">
          <span>ITEMS SOLD</span>
          <span>
            {totalSupply ? getData(totalSupply) - 1 : ""}/
            {maxSupply ? getData(maxSupply) - 1 : ""}
          </span>
        </h6>
        <ProgressBar now={100} />
        <div className="d-flex align-items-center justify-content-between">
          <div className="price">SOLD OUT!</div>

          <a
            href={OPENSEA_URL + "" + contract + "/" + token}
            target={"_blank"}
            rel="noreferrer"
            className="buy-on-opensea"
          >
            Buy on OpenSea
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <h5 className="mb-24">PURCHASE TRACK</h5>

      <div className="purchase-card">
        <h6 className="d-flex align-items-center justify-content-between">
          <span>ITEMS SOLD</span>
          <span>
            {totalSupply ? getData(totalSupply) - 1 : ""}/
            {maxSupply ? getData(maxSupply) - 1 : ""}
          </span>
        </h6>

        {}
        <ProgressBar
          now={getProgress(
            maxSupply ? getData(maxSupply) - 1 : 0,
            totalSupply ? getData(totalSupply) - 1 : 0
          )}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div className="price">${price ? getData(price) / 1000000 : ""}</div>

          <Dropdown>
            <Dropdown.Toggle
              variant="white"
              className="btn-xs"
              id="dropdown-basic"
              onSelect={() => disconnect()}
            >
              BUY ITEM
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {clientId !== "" && (
                <div className="d-flex flex-wrap dropdown-item align-items-center">
                  <img src={Creditcard} alt="Creditcard" />
                  <span style={{ minWidth: 162 }}>
                    Buy with <strong>credit card</strong>
                    <div className="payment-desc">(Powered by Crossmint)</div>
                  </span>
                  <img src={Logos} className="mx-2" alt="Creditcard" />

                  <Crossmint
                    price={price ? getData(price) : undefined}
                    clientId={clientId}
                    tokenId={token}
                    contract={contract}
                  />
                  {/* <Button className="btn btn-xs ms-auto btn-solid">
                  BUY NOW
                </Button> */}
                </div>
              )}
              <div className="d-flex flex-wrap dropdown-item align-items-center">
                <img src={USDC} alt="icoUSDC" />
                <span style={{ minWidth: 162 }}>
                  Buy with <strong>crypto</strong>
                  <div className="payment-desc">USDC on Polygon Network</div>
                </span>
                <img src={Matic} className="mx-2" alt="Creditcard" />

                {typeof price !== "undefined" && (
                  <WalletConnectModal
                    contract={contract}
                    price={price ? getData(price) : 0}
                    token={token}
                  />
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
