import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Image from "../assets/images/coverImg.png";
import Play from "../assets/images/playButton.inline.svg";

function ArtistItemLoading({}: any) {
  return (
    <div className="card">
      <div
        className="card-body"
        style={{ textAlign: "center", paddingTop: 24 }}
      >
        <div className="mask3 artist-image3" style={{ position: "relative" }}>
          <Skeleton
            style={{ top: -8 }}
            width={84}
            height={100}
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
          />
        </div>

        <h4>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </h4>
        <h6>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </h6>

        <hr />
        <div>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </div>
      </div>
    </div>
  );
}

export default ArtistItemLoading;
