import axios from "axios";
import { Dispatch } from "react";

export interface PlayInterface {
  readonly type: "LOAD_PLAY";
  payload: any;
}

export type LoadPlay = PlayInterface;

export const loadPlay = (play: string) => {
  return async (dispatch: Dispatch<PlayInterface>) => {
    dispatch({
      type: "LOAD_PLAY",
      payload: play,
    });
  };
};
