import { LoadArtists } from "../actions/artistsActions";

type ArtistsState = {
  artists: any;
  error: string | undefined;
};

const initialState = {
  artists: [] as any,
  error: undefined,
};

const ArtistsReducer = (
  state: ArtistsState = initialState,
  action: LoadArtists
) => {
  switch (action.type) {
    case "LOAD_ARTISTS":
      return {
        ...state,
        artists: action.payload,
      };

    default:
      return state;
  }
};

export { ArtistsReducer };
